import { useNavigate } from 'react-router-dom'
import { sitar, waves } from '../../assets/images'
import CircularProgressBar from '../../components/CircularProgressBar'
import Navbar from '../../components/Navbar'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { resetState } from '../../redux/slices/audioSlice'
import { RootState } from '../../redux/store'
import './result.scss'

const Result = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { ragaName, fileName } = useAppSelector(
    (state: RootState) => state.audio
  )
  return (
    <div className="result">
      <Navbar />
      <div className="result-header">
        <h1>{ragaName}</h1>
        <p>
          Result is genrated from compairng your audio file with our library
        </p>
      </div>
      <div className="result-wrapper">
        <div className="result-match">
          <p>{fileName}</p>
          <div className="result-img-wrapper">
            <img src={sitar} alt="music" />
          </div>
        </div>
        <div className="result-match">
          <p>It’s a 40 % match</p>
          <CircularProgressBar
            progress={40}
            trackWidth={15}
            trackColor={'#C78F0C'}
            indicatorColor={'#FEC90199'}
            size={120}
            labelColor={'#000'}
          />
        </div>
      </div>
      <div className="result-action-btn">
        <button
          className="action-btn"
          onClick={() => {
            dispatch(resetState())
            navigate('/', {
              replace: true,
            })
          }}
        >
          Try Again
        </button>
      </div>
      <div className="result-waves">
        <img src={waves} alt="waves" />
      </div>
    </div>
  )
}

export default Result
