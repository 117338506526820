import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { logo } from '../../assets/images'
import { TiThMenu } from 'react-icons/ti'
import { FaTimes } from 'react-icons/fa'
import './navbar.scss'

const Navbar = () => {
  const navigate = useNavigate()
  const [toggle, setToggle] = useState(false)
  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-logo" onClick={() => navigate('/')}>
          <img src={logo} alt="logo" />
        </div>

        {/* {toggle ? (
          <FaTimes className="toggle-btn" onClick={() => setToggle(!toggle)} />
        ) : (
          <TiThMenu className="toggle-btn" onClick={() => setToggle(!toggle)} />
        )}

        <div className={toggle ? 'navbar-mobile' : 'navbar-desktop'}>
          <button>Login</button>
          <button>Sign Up</button>
        </div> */}
      </div>
    </nav>
  )
}

export default Navbar
