import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { audioSampleData } from '../../assets/audio/audio'
import { left, right, sitar, waves } from '../../assets/images'
import Loader from '../../components/Loader'
import Navbar from '../../components/Navbar'
import { useAppDispatch } from '../../redux/hooks'
import { setFileName, setRagaName } from '../../redux/slices/audioSlice'
import axios from '../../services/axios'
import './music.scss'

const Music = () => {
  const musicRef = useRef<HTMLInputElement>(null)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)

  const scroll = (scrollOffset: number) => {
    musicRef.current.scrollLeft += scrollOffset
  }

  const sampleAudioRecognize = async (sampleFile: any, fileName: string) => {
    setLoading(true)
    try {
      const blob = await fetch(sampleFile).then((res) => res.blob())
      const file = new File([blob], fileName, {
        type: 'audio/wav',
      })

      const formData = new FormData()
      formData.append('file', file)
      dispatch(setFileName(fileName))
      const res = await axios.post('upload', formData)
      if (res.status === 200) {
        dispatch(setRagaName(res.data))
        navigate('/result', {
          replace: true,
        })
      }
      setLoading(false)
    } catch (err) {
      console.log(err, 'err')
      setLoading(false)
    }
  }

  if (loading) return <Loader />

  return (
    <div className="music">
      <Navbar />
      <div className="music-header">
        <h1>Select Your Music Type</h1>
        <p>Tap to Select Raga</p>
      </div>
      <div className="music-search">
        <input type="text" placeholder="Search" />
      </div>
      <div className="music-container">
        <div className="arrow-btn prev-btn" onClick={() => scroll(-182)}>
          <img src={left} alt="left" />
        </div>
        <div className="music-type-wrapper" ref={musicRef}>
          {audioSampleData.map((ele) => (
            <div
              className="music-type"
              key={ele.id}
              onClick={() => sampleAudioRecognize(ele.file, ele.fileName)}
            >
              <img src={sitar} alt="sitar" />
              <p>{ele.fileName}</p>
            </div>
          ))}
        </div>
        <div className="arrow-btn next-btn" onClick={() => scroll(182)}>
          <img src={right} alt="right" />
        </div>
      </div>
      <div className="music-waves">
        <img src={waves} alt="waves" />
      </div>
    </div>
  )
}

export default Music
