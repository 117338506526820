import './styles.scss'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'
import Home from './views/Home'
import Language from './views/Language'
import Music from './views/Music'
import Record from './views/Record'
import Result from './views/Result'
import FinalResult from './views/FinalResult'

const App = () => {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/language" element={<Language />} />
          <Route path="/music" element={<Music />} />
          <Route path="/record" element={<Record />} />
          <Route path="/result" element={<Result />} />
          <Route path="/progress" element={<FinalResult />} />
          <Route path="/" element={<Navigate to="/home" replace />} />
        </Routes>
      </Router>
    </div>
  )
}

export default App
