import { pause1, start, stop, waves } from '../../assets/images'
import Navbar from '../../components/Navbar'
import './record.scss'

const Record = () => {
  return (
    <div className="record">
      <Navbar />
      <div className="record-header">
        <h1>Record Your Voice</h1>
        <p>Lorem ipsum dolor sit amet.</p>
      </div>
      <div className="record-wrapper">
        <div className="record-pause">
          <img src={pause1} alt="pause" />
        </div>
        <div className="record-start">
          <img src={start} alt="start" />
        </div>
        <div className="record-pause">
          <img src={stop} alt="stop" />
        </div>
      </div>
      <div className="record-waves">
        <img src={waves} alt="waves" />
      </div>
    </div>
  )
}

export default Record
