import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type InitialState = {
  audioFile: File
  ragaName: string
  language: string
  fileName: string
}

const initialState: InitialState = {
  audioFile: null,
  ragaName: '',
  language: '',
  fileName: '',
}

const audioSlice = createSlice({
  name: 'audio',
  initialState,
  reducers: {
    setAudioFile: (state, action: PayloadAction<File>) => {
      state.audioFile = action.payload
    },
    setRagaName: (state, action: PayloadAction<string>) => {
      state.ragaName = action.payload
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload
    },
    setFileName: (state, action: PayloadAction<string>) => {
      state.fileName = action.payload
    },
    resetState: () => initialState,
  },
})

export default audioSlice.reducer
export const {
  setAudioFile,
  setRagaName,
  setLanguage,
  setFileName,
  resetState,
} = audioSlice.actions
