import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { waves } from '../../assets/images'
import Navbar from '../../components/Navbar'
import Select from '../../components/Select'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { setLanguage } from '../../redux/slices/audioSlice'
import { RootState } from '../../redux/store'
import './language.scss'

const Language = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { language } = useAppSelector((state: RootState) => state.audio)
  const options = [
    {
      id: 1,
      label: 'Indian Classical',
      value: 'Indian Classical',
    },
    {
      id: 2,
      label: 'Western Music',
      value: 'Western Music',
    },
    {
      id: 3,
      label: 'Indo Western Music',
      value: 'Indo Western Music',
    },
  ]

  useEffect(() => {
    if (!!language) navigate('/music')
  }, [language])

  return (
    <div className="language">
      <Navbar />
      <div className="language-header">
        <h1>Select Your Language</h1>
        <p>Lorem ipsum dolor sit amet.</p>
      </div>
      <div className="language-select">
        <Select
          options={options}
          value={language}
          onChange={(e) => dispatch(setLanguage(e.target.value))}
        />
      </div>
      <div className="language-waves">
        <img src={waves} alt="waves" />
      </div>
    </div>
  )
}

export default Language
