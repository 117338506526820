import { Chart } from 'react-chartjs-2'
import type { ChartData, ChartArea } from 'chart.js'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js'
import './graph.scss'
import { useEffect, useRef, useState } from 'react'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
)

const lineOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        display: false,
      },
      //   stacked: true,
    },
    y: {
      min: 0,
      grid: {
        display: true,
      },
      //   stacked: true,
    },
  },
  tension: 0.4,
  plugins: {
    legend: {
      position: 'top' as const,
    },
  },
}

const labels = ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

const lineData = {
  labels,
  datasets: [
    {
      label: 'Progress',
      data: [0, 2, 8, 9, 12, 70],
      backgroundColor: '#1B59F845',
      borderColor: '#1B59F8',
      hoverBackgroundColor: '#1B59F8',
      hoverBorderColor: '#1B59F8',
      fill: false,
    },
  ],
}

const createGradient = (ctx: CanvasRenderingContext2D, area: ChartArea) => {
  const colorStart = '#1B59F850'
  const colorEnd = '#1B59F8'

  const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top)

  gradient.addColorStop(0, colorStart)
  gradient.addColorStop(1, colorEnd)

  return gradient
}

const Graph = () => {
  const chartRef = useRef<ChartJS>(null)
  const [chartData, setChartData] = useState<ChartData<'line'>>({
    datasets: [],
  })

  useEffect(() => {
    const chart = chartRef.current

    if (!chart) {
      return
    }

    const chartData = {
      ...lineData,
      datasets: lineData.datasets.map((dataset) => ({
        ...dataset,
        borderColor: createGradient(chart.ctx, chart.chartArea),
      })),
    }

    setChartData(chartData)
  }, [])

  return (
    <div className="graph">
      <Chart
        ref={chartRef}
        type="line"
        data={chartData}
        options={lineOptions}
      />
      ;
    </div>
  )
}

export default Graph
