import { waves } from '../../assets/images'
import Graph from '../../components/Graph'
import Navbar from '../../components/Navbar'
import './finalResult.scss'

const FinalResult = () => {
  return (
    <div className="final-result">
      <Navbar />
      <div className="final-result-header">
        <h1>Your Audio Progress</h1>
        <p>
          Result is genrated from compairng your audio file with our library
        </p>
      </div>
      <div className="final-result-wrapper">
        <Graph />
      </div>
      <div className="final-result-waves">
        <img src={waves} alt="waves" />
      </div>
    </div>
  )
}

export default FinalResult
