import './circularProgressBar.scss'

/*
calculating circle center

size = 100
strokeWidth = 10
center = size / 2
       = 100 / 2 = 50
radius = center - strokeWidth 
       = 50 - 10 = 40

calculating circumference of circle

progress = 0
arcLength = 2 * π * radius 
          = 2 * 3.14 * 40 = 251.2
arcOffset = arcLength * ((100 - progress)/100) 
          = 251.2 * ((100 - 0)/100) = 251.2

e.g: 
progress = 25
arcOffset = arcLength * ((100 - progress)/100) 
          = 251.2 * ((100 - 25)/100) = 188.4
*/

interface Props {
  size: number
  progress: number
  trackWidth: number
  trackColor: string
  indicatorColor: string
  labelColor: string
  spinnerMode?: boolean
  spinnerSpeed?: number
}

const CircularProgressBar = ({
  size,
  progress,
  trackWidth,
  trackColor,
  indicatorColor,
  labelColor,
  spinnerMode = false,
  spinnerSpeed = 1,
}: Props) => {
  const center = size / 2
  const radius = center - trackWidth
  const dashArray = 2 * Math.PI * radius
  const dashOffset = dashArray * ((100 - progress) / 100)

  let hideLabel = size < 100 || spinnerMode ? true : false

  return (
    <div className="svg-pi-wrapper" style={{ width: size, height: size }}>
      <svg className="svg-pi" style={{ width: size, height: size }}>
        <circle
          className="svg-pi-track"
          cx={center}
          cy={center}
          fill="transparent"
          r={radius}
          stroke={trackColor}
          strokeWidth={trackWidth}
          strokeDasharray={'25%, 1%'}
        />
        <circle
          className={`svg-pi-indicator ${
            spinnerMode ? 'svg-pi-indicator--spinner' : ''
          }`}
          style={{ animationDuration: `${spinnerSpeed * 1000}` }}
          cx={center}
          cy={center}
          fill="transparent"
          r={radius}
          stroke={indicatorColor}
          strokeWidth={trackWidth}
          strokeDasharray={dashArray}
          strokeDashoffset={dashOffset}
          strokeLinecap={'butt'}
        />
      </svg>
      {!hideLabel && (
        <div className="svg-pi-label" style={{ color: labelColor }}>
          {!spinnerMode && (
            <span className="svg-pi-label__progress">
              {`${progress > 100 ? 100 : progress}%`}
            </span>
          )}
        </div>
      )}
    </div>
  )
}

export default CircularProgressBar
