import begada from './begada.wav'
import kalyani from './kalyani.wav'
import madhyamavati from './madhyamavati.wav'
import sahana from './sahana.wav'

export const audioConst = {
  begada,
  kalyani,
  madhyamavati,
  sahana,
}

export const audioSampleData = [
  {
    id: 1,
    file: begada,
    fileName: 'begada.wav',
  },
  {
    id: 2,
    file: kalyani,
    fileName: 'kalyani.wav',
  },
  {
    id: 3,
    file: madhyamavati,
    fileName: 'madhyamavati.wav',
  },
  {
    id: 4,
    file: sahana,
    fileName: 'sahana.wav',
  },
]
