// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/down.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".select-wrapper{width:100%}.select-wrapper select{width:100%;background-color:#fefeff;border-radius:21px;border:none;outline:none;font-family:\"Poppins\";font-size:1em;padding:.8em 2em;box-shadow:0 10px 10px 5px rgba(136,153,255,.25);-webkit-appearance:none;-moz-appearance:none;appearance:none;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:center right .5em;background-size:25px}", "",{"version":3,"sources":["webpack://./src/components/Select/select.scss","webpack://./src/scss/_variables.scss"],"names":[],"mappings":"AAEA,gBACI,UAAA,CAEA,uBACI,UAAA,CACA,wBCFE,CDGF,kBAAA,CACA,WAAA,CACA,YAAA,CACA,qBAAA,CACA,aAAA,CACA,gBAAA,CACA,gDAAA,CAEA,uBAAA,CACA,oBAAA,CACA,eAAA,CACA,wDAAA,CACA,2BAAA,CACA,qCAAA,CACA,oBAAA","sourcesContent":["@import '../../scss/variables';\n\n.select-wrapper {\n    width: 100%;\n\n    select {\n        width: 100%;\n        background-color: $white-2;\n        border-radius: 21px;\n        border: none;\n        outline: none;\n        font-family: 'Poppins';\n        font-size: 1em;\n        padding: .8em 2em;\n        box-shadow: 0 10px 10px 5px rgba($color: $purple-1, $alpha: 0.25);\n\n        -webkit-appearance: none;\n        -moz-appearance: none;\n        appearance: none;\n        background-image: url('../../assets/images/down.png');\n        background-repeat: no-repeat;\n        background-position: center right .5em;\n        background-size: 25px;\n    }\n}","$primary: #8899FF;\n$secondary: #a55900;\n$bg: #F0E9E9;\n\n$white-1: #ffffff;\n$white-2: #FEFEFF;\n\n$purple-1: #8899FF;\n\n$gray-1: #9E9898;\n$gray-2: #464646;\n\n$teal: #16A799;\n\n$blue-1: #0C3BE8;\n\n\n$black-1: #000000"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
