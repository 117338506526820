import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  music,
  music2,
  musicBig,
  musicLeft,
  musicRight,
  waves,
} from '../../assets/images'
import Loader from '../../components/Loader'
import Navbar from '../../components/Navbar'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import {
  setAudioFile,
  setFileName,
  setRagaName,
} from '../../redux/slices/audioSlice'
import { RootState } from '../../redux/store'
import axios from '../../services/axios'
import './home.scss'

const Home = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const audioUploadRef = useRef<any>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const { audioFile } = useAppSelector((state: RootState) => state.audio)

  const onBrowseAudio = () => {
    audioUploadRef.current.click()
  }

  useEffect(() => {
    if (!!audioFile) recognize()
  }, [audioFile])

  const recognize = async () => {
    setLoading(true)
    try {
      const formData = new FormData()
      formData.append('file', audioFile)
      const res = await axios.post('upload', formData)
      if (res.status === 200) {
        dispatch(setRagaName(res.data))
        dispatch(setFileName(audioFile.name))
        navigate('/result', {
          replace: true,
        })
      }
      setLoading(false)
    } catch (err) {
      console.log(err, 'err')
      setLoading(false)
    }
  }

  if (loading) return <Loader />

  return (
    <div className="home">
      <Navbar />
      <div className="home-hero-img">
        <img src={musicLeft} alt="music" />
        <img src={music} alt="music" />
        <img src={musicRight} alt="music" />
      </div>
      <div className="home-content">
        <p>
          Raga Recognizer is a Neural Networks based solution for recognizing 40
          different types of ragas from a provided audio file
        </p>
      </div>
      <div className="home-hero-img-big-screen">
        <img src={musicBig} alt="music" />
        <img src={music2} alt="music" />
      </div>
      <div className="home-action-btns">
        <button className="action-btn" onClick={onBrowseAudio}>
          Upload Audio
        </button>
        <button className="action-btn" onClick={() => navigate('/language')}>
          Get Started
        </button>
      </div>
      <div className="home-waves">
        <img src={waves} alt="waves" />
      </div>
      <input
        type="file"
        hidden
        accept={'audio/mp3,audio/wav'}
        ref={audioUploadRef}
        onChange={(e: any) => {
          dispatch(setAudioFile(e.target.files[0]))
        }}
      />
    </div>
  )
}

export default Home
