import { ChangeEventHandler } from 'react'
import './select.scss'

type Option = {
  value: string
  label: string
  id: number
}

interface Props {
  options: Option[]
  value: string
  onChange: ChangeEventHandler<HTMLSelectElement>
}

const Select = ({ options, value, onChange }: Props) => {
  return (
    <div className="select-wrapper">
      <select value={value} onChange={onChange}>
        <option disabled value="">
          Select Your Language
        </option>
        {options.map((option) => (
          <option key={option.id} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  )
}

export default Select
